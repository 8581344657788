// import { Agent } from '@atproto/api'
import { BrowserOAuthClient, OAuthAgent } from '@atproto/oauth-client-browser'

async function main() {

    const oauthClient = await BrowserOAuthClient.load({
        clientId: 'https://atp-oauth-test.pages.dev/client-metadata.json',
        handleResolver: 'https://bsky.social/',
    })

    const result = await oauthClient.init()
    const agent = result?.agent

    if (result) {

        if ('state' in result) {
            console.log('The user was just redirected back from the authorization page')
        }

        console.log(`The user is currently signed in as`, result);

        /*const doThisThing = async () => {

            console.log(await result?.agent.oauthAgent);
        }
        
        doThisThing();*/
    }

    //const session = result?.session

    if (!agent) {

        const handle = prompt('Enter your atproto handle to authenticate')
        if (!handle) throw new Error('Authentication process canceled by the user')
      
        const url = await oauthClient.authorize(handle)
      
        // Redirect the user to the authorization page
        window.open(url, '_self', 'noopener')
      
        // Protect against browser's back-forward cache
        await new Promise<never>((resolve, reject) => {
            setTimeout(
                reject,
                10_000,
                new Error('User navigated back from the authorization page'),
            )
        })
    }
      
    if (agent) { //
        
        //const agent = new Agent(session)

        const fetchProfile = async () => {
            const profile = await agent.getProfile({ actor: agent.did })
            return profile.data
        }

        // Update the user interface

        document.body.textContent = `Authenticated as ${agent.did}`

        const profileBtn = document.createElement('button')
        document.body.appendChild(profileBtn)
        profileBtn.textContent = 'Fetch Profile'
        profileBtn.onclick = async () => {
            const profile = await fetchProfile()
            outputPre.textContent = JSON.stringify(profile, null, 2)
        }

        const logoutBtn = document.createElement('button')
        document.body.appendChild(logoutBtn)
        logoutBtn.textContent = 'Logout'
        logoutBtn.onclick = async () => {
            await result.agent.oauthAgent.signOut(); // await session.signOut()
            window.location.reload()
        }

        const outputPre = document.createElement('pre')
        document.body.appendChild(outputPre)
    }
}

document.addEventListener('DOMContentLoaded', main)